import { render, staticRenderFns } from "./TheSpaceInstances.vue?vue&type=template&id=1dc9e083&scoped=true&"
import script from "./TheSpaceInstances.vue?vue&type=script&lang=js&"
export * from "./TheSpaceInstances.vue?vue&type=script&lang=js&"
import style0 from "./TheSpaceInstances.vue?vue&type=style&index=0&id=1dc9e083&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc9e083",
  null
  
)

export default component.exports