<template>
    <div>
        <div v-if="!fetchingInstances">
            <div class="d-flex flex-wrap align-center">
                <v-checkbox v-for="(item, index) in Object.keys(userCheckboxBinders)" :key="index" class="mx-4" v-model="userCheckboxBinders[item].status">
                    <template v-slot:label>
                        <div class="d-flex align-center">
                            <v-icon class="mr-1" small color="secondary">{{ userCheckboxBinders[item].icon }}</v-icon>
                            <span class="secondary--text caption text-uppercase">{{ item }}</span>
                        </div>
                    </template>
                </v-checkbox>
                <v-spacer />
                <v-text-field
                    v-model="instanceSearch"
                    autofocus
                    label="Filter"
                    solo
                    flat
                    background-color="grey lighten-4"
                    dense
                    hide-details
                    clearable
                    prepend-inner-icon="mdi-filter"
                    class="mr-2"></v-text-field>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="$store.dispatch('spaceStore/fetchSpaceInstances', $route.params.sid)" text icon>
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </div>
            <v-divider class="mb-5"></v-divider>
            <v-data-iterator :items="spaceInstances" :search="instanceSearch">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="item.iid">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon v-if="item.long_id === instanceFixedNames.MASTER_INSTANCE" x-large>star</v-icon>
                                        <v-icon v-else-if="item.long_id === instanceFixedNames.DISTRIBUTED_INSTANCE" x-large>share</v-icon>
                                        <v-icon v-else x-large>group</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <router-link
                                                :to="{
                                                    name: 'snapshot-overview',
                                                    params: { oid: $route.params.oid, sid: $route.params.sid, iid: item.iid, snid: item.dev_snid }
                                                }"
                                                style="text-decoration: none">
                                                <v-hover v-slot:default="{ hover }">
                                                    <span :class="[hover ? 'secondary--text' : 'secondary--text', 'font-weight-medium']">
                                                        {{ item.long_id }}
                                                    </span>
                                                </v-hover>
                                            </router-link>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3">{{ item.description }}</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-subtitle style="width: 30%">
                                            <ul style="list-style-type: none">
                                                <div v-if="spaceAdmins.length && userCheckboxBinders.administrators.status">
                                                    <ComponentWithStyledBorder v-for="admin in spaceAdmins" :key="admin.ucid">
                                                        <li>
                                                            <div class="d-flex align-center">
                                                                <v-icon class="mr-1 ml-1">security</v-icon>
                                                                {{ admin.full_name }}
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <div v-if="instancesWithNonAdminRole.includes(item.long_id)">
                                                    <ComponentWithStyledBorder>
                                                        <li v-for="(role, index) in filterInstanceRolesPerInstance(item.long_id)" :key="index">
                                                            <div v-if="role.Instance === item.long_id" class="d-flex align-center">
                                                                <v-icon v-if="role.role_name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1">
                                                                    visibility
                                                                </v-icon>
                                                                <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">edit</v-icon>
                                                                <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_OBSERVER" class="mr-1 ml-1">
                                                                    visibility_off
                                                                </v-icon>
                                                                {{ role.full_name }}
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <v-btn small text block class="secondary--text justify-start" @click="inviteToInstance(item.long_id)">
                                                    <v-icon small color="primary">add</v-icon>
                                                    Invite users
                                                </v-btn>
                                            </ul>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                            <v-list-item-action>
                                <InstanceDeleteDialog
                                    :instanceId="parseInt(item.iid, 10)"
                                    :instanceName="item.long_id"
                                    :instanceDescription="item.description"
                                    :masterInstanceId="masterInstance.iid.toString()"
                                    :masterDevelopmentSnapshotId="masterInstance.dev_snid.toString()"></InstanceDeleteDialog>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <add-instance-dialog>
                        <v-list nav>
                            <v-list-item :to="{ name: $router.name }" active-class="activeClass" two-line>
                                <v-row align="center">
                                    <v-col cols="1">
                                        <v-list-item-avatar>
                                            <v-icon x-large>add</v-icon>
                                        </v-list-item-avatar>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-list-item-content>
                                            <v-list-item-title>Add new instance</v-list-item-title>
                                        </v-list-item-content>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </add-instance-dialog>
                </template>
            </v-data-iterator>
        </div>
        <div v-else-if="fetchingInstances">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6">
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
        <div v-else>
            <v-banner class="mb-5" single-line>
                <v-avatar slot="icon" color="white" size="30"><v-icon color="info">info</v-icon></v-avatar>
                <div class="d-flex flex-column">
                    <span class="secondary--text font-weight-bold">No instances found</span>
                </div>
                <template v-slot:actions>
                    <add-instance-dialog>
                        <v-btn color="primary">
                            <v-icon small>add</v-icon>
                            <span class="font-weight-bold caption">Add New Instance</span>
                        </v-btn>
                    </add-instance-dialog>
                </template>
            </v-banner>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const InstanceDeleteDialog = () => import('@/modules/instance/components/TheInstanceDeleteDialog')
const AddInstanceDialog = () => import('@/modules/space/components/TheSpaceAddInstanceDialog')
const ComponentWithStyledBorder = () => import('@/components/ComponentWithStyledBorder')
export default {
    mixins: [enumsData],
    components: { InstanceDeleteDialog, AddInstanceDialog, ComponentWithStyledBorder },
    data() {
        return {
            instanceSearch: '',
            expand: false,
            userCheckboxBinders: {
                administrators: { status: true, icon: 'security' },
                editors: { status: true, icon: 'edit' },
                viewers: { status: true, icon: 'visibility' },
                observers: { status: true, icon: 'visibility_off' }
            }
        }
    },
    methods: {
        inviteToInstance: function (instanceName) {
            this.$emit('instanceData', { data: { instanceName } })
        },
        filterInstanceRolesPerInstance: function (instanceName) {
            return this.instanceRoles.filter(instance => instance.Instance === instanceName)
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInstances', 'spaceUsers', 'fetchingInstances']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        instanceRoles() {
            if (this.spaceUsers) {
                return this.spaceUsers
                    .filter(user => user.instance_viewer_roles.length || user.instance_editor_roles.length || user.instance_observer_roles.length)
                    .map(user => {
                        let finalData = []
                        if (this.userCheckboxBinders.editors.status) {
                            const userEditorData = user.instance_editor_roles.map(instance =>
                                Object.assign(instance, { ucid: user.ucid, uid: user.uid, full_name: user.full_name, email: user.email })
                            )
                            finalData = finalData.concat(userEditorData)
                        }
                        if (this.userCheckboxBinders.viewers.status) {
                            const userViewerData = user.instance_viewer_roles.map(instance =>
                                Object.assign(instance, { ucid: user.ucid, uid: user.uid, full_name: user.full_name, email: user.email })
                            )
                            finalData = finalData.concat(userViewerData)
                        }
                        if (this.userCheckboxBinders.observers.status) {
                            const userObserverData = user.instance_observer_roles.map(instance =>
                                Object.assign(instance, { ucid: user.ucid, uid: user.uid, full_name: user.full_name, email: user.email })
                            )
                            finalData = finalData.concat(userObserverData)
                        }
                        return finalData
                    })
                    .reduce((a, b) => [...a, ...b], [])
            }
            return []
        },
        instancesWithNonAdminRole() {
            return this.instanceRoles.map(instance => instance.Instance)
        },
        spaceAdmins() {
            return this.spaceUsers.filter(user => user.space_role === this.roleTypes.SPACE_ADMIN)
        },
        masterInstance() {
            const masterInstance = this.spaceInstances.find(instance => instance.long_id === this.instanceFixedNames.MASTER_INSTANCE)
            return masterInstance
        }
    }
}
</script>

<style scoped lang="scss">
.activeClass::before {
    opacity: 0;
}
</style>
